<template>    
    <div class="content-wrapper">
        <div v-if="isList" class="content pt-0">
            <bo-page-title />
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>                                                                  

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk"  :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Bangsal -- " @input="doFill" v-model="filter.ranap_bangsal"  :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                     
                
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>NO. KEDATANGAN</th>
                                <th>TANGGAL MASUK</th>
                                <th>NAMA/NO KTP</th>
                                <th>DOKTER DPJP</th>                                
                                <th>NO RM</th>
                                <th>RUANGAN</th>
                                <th>JENIS RAWAT INAP</th>
                                <th>TANGGAL LAHIR</th>    
                                <th>USIA</th>    
                                <th>PPJP</th>
                                <th>STATUS</th>
                                <th>AKSI</th>
                            </tr>
                        </thead>
                        
                        <tbody v-if="(dataList||[]).length">
                            <template v-for="(v,k) in (dataList||[])">
                                <tr :key="k" :class="!v.aranr_ppjp && !v.aranr_perawat_pelaksana ? 'table-warning':''">
                                    <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                    <td>
                                        <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                                    </td>
                                    <td>
                                        <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                                    </td>
                                    <td>
                                        <div>
                                        <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                                        <br>
                                        {{v.ap_code||"-"}}</div>
                                        <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                                    </td>
                                    <td>{{v.bu_full_name||"-"}}</td>
                                    <td>
                                        <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                        <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                                        <div class="text-success-700 font-weight-semibold">
                                            {{v.no_reg||"-"}}                                    
                                        </div>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                                        <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                                        <span v-else> - </span>
                                    </td>
                                    <td>
                                        <div class="font-weight-semibold">
                                            {{v.ap_usia_with_ket || "-"}}
                                        </div>
                                    </td>
                                    <td>
                                        {{v.nama_ppjp||"-"}}
                                    </td>
                                    <td>
                                        <div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan PPJP</div>
                                        <div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan Perawat Pelaksana</div>
                                        <template v-else>
                                            <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>
                                        
                                            <template v-if="v.aranr_status == 1">
                                                <div v-if="v.aranr_is_done_perawat != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Perawat</div>
                                                
                                                <div v-if="v.aranr_is_done_dokter != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Dokter</div>
                                            </template>
                                            
                                        </template>
                                            

                                    </td>
                                    <td>
                                        <!-- lab sama radio di pisah tktnya kedepannya berubah -->
                                        <template v-if="moduleRole('cppt_ppa_lab')">
                                          <a href="javascript:;" @click="toCPPT(v.aranr_id)" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-info border-info" title="Lihat Detail">
                                            <i class="icon-file-eye"></i>
                                          </a>
                                          <a href="javascript:;" @click="addCPPT(v,'PPALAB')" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" title="Tambah CPPT">
                                            <i class="fa-solid fa-notes-medical"></i>
                                          </a>
                                        </template>

                                        <template v-if="moduleRole('cppt_ppa_radio')">
                                          <a href="javascript:;" @click="toCPPT(v.aranr_id)" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-info border-info" title="Lihat Detail">
                                            <i class="icon-file-eye"></i>
                                          </a>
                                          <a href="javascript:;" @click="addCPPT(v,'PPARADIO')" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" title="Tambah CPPT">
                                            <i class="fa-solid fa-notes-medical"></i>
                                          </a>
                                        </template>

                                        <template v-if="moduleRole('cppt_dietisien')">
                                          <a href="javascript:;" @click="toCPPT(v.aranr_id)" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-info border-info" title="Lihat Detail">
                                            <i class="icon-file-eye"></i>
                                          </a>
                                          <a href="javascript:;" @click="addCPPT(v,'DIETISIEN')" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" title="Tambah CPPT">
                                            <i class="fa-solid fa-notes-medical"></i>
                                          </a>
                                        </template>
                                        
                                        <template v-if="moduleRole('cppt_ppa_farmasi')">
                                          <a href="javascript:;" @click="toCPPT(v.aranr_id)" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-info border-info" title="Lihat Detail">
                                            <i class="icon-file-eye"></i>
                                          </a>
                                          <a href="javascript:;" @click="addCPPT(v,'PPAFARMASI')" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" title="Tambah CPPT">
                                            <i class="fa-solid fa-notes-medical"></i>
                                          </a>
                                        </template>

                                        <template v-if="moduleRole('cppt_ppa_fisio')">
                                          <a href="javascript:;" @click="toCPPT(v.aranr_id)" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-info border-info" title="Lihat Detail">
                                            <i class="icon-file-eye"></i>
                                          </a>
                                          <a href="javascript:;" @click="addCPPT(v,'PPAFISIO')" v-b-tooltip.hover.right class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" title="Tambah CPPT">
                                            <i class="fa-solid fa-notes-medical"></i>
                                          </a>
                                        </template>
                                    </td>

                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>

            </div>

        </div>
    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    const moment = require('moment')
    
    // import Datepicker from 'vuejs-datepicker'


    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
            DateRangePicker,
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    isShowBangsal: this.isShowBangsal,
                    infoBangsal: this.infoBangsal,
                    katalogBangsal: this.katalogBangsal
                }
            },
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                },

                rowEdit : {},
                rowPasien : {},
                rowAdditional: {},
                mRanapBangsal : [],
                mPPJP: []
            }
        },
        mounted(){
            this.filter.status_jaminan = 'ALL'
            this.filter.cara_masuk = 'ALL'
            this.filter.ranap_bangsal = this.user.bu_bangsal_id
            
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            addCPPT(row,type){
              this.loadingOverlay = true
              let data = {
                  idPasien : row.aranr_ap_id,
                  regId : row.aranr_id,
                  jenisCPPT: type        
              }
              data.type = 'init-cppt'
              Gen.apiRest(
                  "/do/"+this.modulePage,
                  {data: data}, 
                  "POST"
              ).then(res=>{
                  let resp = res.data
                  this.loadingOverlay = false
                  this.$router.push({ name: resp.menuCPPT, params: {pageSlug: row.aranr_id, cpptNo: resp.arancp_id} })
                  .catch(()=>{})
              })  
            },
            toMonitoring(regID){
                this.$router.push({ name: 'RanapMonitoring', params: {pageSlug: regID}}).catch(()=>{})
            },      
            toCPPT(regID){
                this.$router.push({ name: 'RanapCPPT', params: {pageSlug: regID}}).catch(()=>{})
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                
                if(this.isList){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }
    }
</script>